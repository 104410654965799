// envelope animation
window.onload = function(){
  var tl = new TimelineLite({delay: 0.5}),
      firstBg = document.querySelectorAll('.text1-bg'),
      secBg = document.querySelectorAll('.text2-bg'),
      word  = document.querySelectorAll('.text1');

  tl
      .to(firstBg, 0.2, {scaleX:1})
      .to(secBg, 0.2, {scaleX:1})
      .to(word, 0.1, {opacity:1}, "-=0.1")
      .to(firstBg, 0.2, {scaleX:0})
      .to(secBg, 0.2, {scaleX:0});
}

function closePopupU() {
  $('#case_view').html('');
}

//burger
$('.burger-botton-1').click(function(){
  $(this).toggleClass('active');
  let buttonClick = false;
  if($(this).hasClass('active')){
    buttonClick = true;
  }
  buttonClick = false;
  $('.burger-1').toggleClass('active');
});

$('.block-2-link-1').click(function(){
  $("#popupScrollWrapper").toggleClass('active');
  if($("#popupScrollWrapper").is(".active")){
    $("#popupScrollWrapper").css({"display": "block"});
  }
  $("#popupClose").click(function(){
    $("#popupScrollWrapper").css({"display": "none"});
  })
  $("#popupScrollWrapper").toggleClass('active');
  return false;
});
//delete this func
$("#popupClose").click(function(){
  $("#popupScrollWrapper").css({"display": "none"});
})
//delete this func

$('.block-2-content-2-content-1').click(function(){
  $("#popupScrollWrapper1").toggleClass('active');
  if($("#popupScrollWrapper1").is(".active")){
    $("#popupScrollWrapper1").css({"display": "block"});
  }
  $("#popupClose1").click(function(){
    $("#popupScrollWrapper1").css({"display": "none"});
  })
  $("#popupScrollWrapper1").toggleClass('active');
  return false;
});
$("#popupClose1").click(function(){
  $("#popupScrollWrapper1").css({"display": "none"});
})


$('.filter-header-label-1').click(function(){
  $('.filter-header-label-1').toggleClass('active');
  if($('.filter-header-label-1').hasClass('active')){
    $('.span-checkbox-1').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-1').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-2').click(function(){
  $('.filter-header-label-2').toggleClass('active');
  if($('.filter-header-label-2').hasClass('active')){
    $('.span-checkbox-2').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-2').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-3').click(function(){
  $('.filter-header-label-3').toggleClass('active');
  if($('.filter-header-label-3').hasClass('active')){
    $('.span-checkbox-3').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-3').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-4').click(function(){
  $('.filter-header-label-4').toggleClass('active');
  if($('.filter-header-label-4').hasClass('active')){
    $('.span-checkbox-4').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-4').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-5').click(function(){
  $('.filter-header-label-5').toggleClass('active');
  if($('.filter-header-label-5').hasClass('active')){
    $('.span-checkbox-5').css('border','2px solid var(--pink)');
    $('.filter-header-label-6').removeClass('active');
    $('.span-checkbox-9').css('border','2px solid var(--textGrey)');
    $('.filter-header-label-7').removeClass('active');
    $('.span-checkbox-13').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-6').click(function(){
  $('.filter-header-label-6').toggleClass('active');
  if($('.filter-header-label-6').hasClass('active')){
    $('.span-checkbox-9').css('border','2px solid var(--pink)');
    $('.filter-header-label-5').removeClass('active');
    $('.span-checkbox-5').css('border','2px solid var(--textGrey)');
    $('.filter-header-label-7').removeClass('active');
    $('.span-checkbox-13').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-7').click(function(){
  $('.filter-header-label-7').toggleClass('active');
  if($('.filter-header-label-7').hasClass('active')){
    $('.span-checkbox-13').css('border','2px solid var(--pink)');
    $('.filter-header-label-5').removeClass('active');
    $('.span-checkbox-5').css('border','2px solid var(--textGrey)');
    $('.filter-header-label-6').removeClass('active');
    $('.span-checkbox-9').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-8').click(function(){
  $('.filter-header-label-8').toggleClass('active');
  if($('.filter-header-label-8').hasClass('active')){
    $('.span-checkbox-17').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-17').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-header-label-9').click(function(){
  $('.filter-header-label-9').toggleClass('active');
  if($('.filter-header-label-9').hasClass('active')){
    $('.span-checkbox-18').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-18').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-subheader-label-1-1').click(function(){
  $('.filter-subheader-label-1-1').toggleClass('active');
  if($('.filter-subheader-label-1-1').hasClass('active')){
    $('.span-checkbox-6').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-6').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-subheader-label-2-1').click(function(){
  $('.filter-subheader-label-2-1').toggleClass('active');
  if($('.filter-subheader-label-2-1').hasClass('active')){
    $('.span-checkbox-7').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-7').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-subheader-label-3-1').click(function(){
  $('.filter-subheader-label-3-1').toggleClass('active');
  if($('.filter-subheader-label-3-1').hasClass('active')){
    $('.span-checkbox-8').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-8').css('border','2px solid var(--textGrey)');
  }
})

$('.filter-subheader-label-1-2').click(function(){
  $('.filter-subheader-label-1-2').toggleClass('active');
  if($('.filter-subheader-label-1-2').hasClass('active')){
    $('.span-checkbox-10').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-10').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-subheader-label-2-2').click(function(){
  $('.filter-subheader-label-2-2').toggleClass('active');
  if($('.filter-subheader-label-2-2').hasClass('active')){
    $('.span-checkbox-11').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-11').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-subheader-label-3-2').click(function(){
  $('.filter-subheader-label-3-2').toggleClass('active');
  if($('.filter-subheader-label-3-2').hasClass('active')){
    $('.span-checkbox-12').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-12').css('border','2px solid var(--textGrey)');
  }
})

$('.filter-subheader-label-1-3').click(function(){
  $('.filter-subheader-label-1-3').toggleClass('active');
  if($('.filter-subheader-label-1-3').hasClass('active')){
    $('.span-checkbox-14').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-14').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-subheader-label-2-3').click(function(){
  $('.filter-subheader-label-2-3').toggleClass('active');
  if($('.filter-subheader-label-2-3').hasClass('active')){
    $('.span-checkbox-15').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-15').css('border','2px solid var(--textGrey)');
  }
})
$('.filter-subheader-label-3-3').click(function(){
  $('.filter-subheader-label-3-3').toggleClass('active');
  if($('.filter-subheader-label-3-3').hasClass('active')){
    $('.span-checkbox-16').css('border','2px solid var(--pink)');
  }
  else{
    $('.span-checkbox-16').css('border','2px solid var(--textGrey)');
  }
})

//Parallax

let homePage = document.querySelector('#main'),
    sectorsPage = document.querySelector('#sectors'),
    servicesPage = document.querySelector('#services'),
    whoweworkPage = document.querySelector('#WhoWeWorkWhith'),
    jobPage = document.querySelector('#jobBoard');

if(homePage) {
  //Home Page
  var homeYellowTreangle = new Rellax('.yellowTreangle', {
    speed: 3,
  });
  var homeGreenTreangle = new Rellax('.greenTreangle', {
    speed: 3,
  });
  var homeBlock3 = new Rellax('.block3-content-wrapper');
  var homeVideo1 = new Rellax('.video-1');
  var homeVideo2 = new Rellax('.video-block-2-wrapper');
} else if (sectorsPage) {
  //Sectors Page
  var sectorsBlock1Figure = new Rellax('.block1-figure1-rellax');
  var sectorsBlock2Img = new Rellax('.block-2-img-1');
  var sectorsBlock2imgAdaptive = new Rellax('.block-2-img');
  var sectorsBlock4Figure = new Rellax('.block-4-img');
  var sectorsBlock4FigureAdaptive = new Rellax('.block-4-adaptive');
  var sectorsBlock6Figure = new Rellax('.block6-figure1-rellax');
  var sectorsImg12 = new Rellax('.img12');
} else if (servicesPage) {
  //Services Page
  var servicesBlock1Img = new Rellax('.block-1-img-1-rellax');
  var servicesBlock2Img = new Rellax('.block-2-img-1-rellax');
  var servicesBlock2Img2 = new Rellax('.block-2-img-2-rellax');
  var imgOverContent1 = new Rellax('.decor-img-1 img');
  var imgOverContent2 = new Rellax('.decor-img-2 .image-cont');
  var imgOverContent3 = new Rellax('.decor-img-3 img');
} else if (whoweworkPage) {
  //Who we work Page
  var whoweserveImgHeader = new Rellax('.imgHeader-rellax');
  var whoweserveImg1 = new Rellax('.img1-whoWeWorkWhith');
  var whoweserveImg2 = new Rellax('.img2-whoWeWorkWhith');
}

//Home Page MacOS background fix
if(homePage) {
  if(document.querySelector('.video-1.home-page')) {
    let homePageBlock1 = document.querySelector('#main .block1-wrapper');
    if( /Mac/i.test(navigator.userAgent) ) {
      homePageBlock1.classList.add('macOS-bg');
    } else {
      homePageBlock1.classList.remove('macOS-bg');
    }
  }
}

//Lottie Logo animation
let siteLink = window.location.pathname;
function logoPath() {
  if(siteLink.includes('/job/') || siteLink.includes('/blog/')) {
    return '../app/public/js/animate/Talisman_Logo_1.json';
  } else {
    return './app/public/js/animate/Talisman_Logo_1.json';
  }
}
lottie.loadAnimation({
  container: document.querySelector('.block1-logo-anim'),
  renderer: 'svg',
  loop: false,
  autoplay: true,
  path: logoPath(),
})

// --------------------------------------------------------------------------------------------------------------
$('.slider-logo').slick({
  slidesToShow: 5,
  dots:false,
  arrows: false,
  autoplay:true,
  pauseOnHover:false,
  autoplaySpeed: "2000",
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
});

///////////////////////////////////////////
if(jobPage) {
  let block2ContentHeader = document.querySelectorAll('.block-2-content-2-content-header');
  let block2Arrow = document.querySelectorAll('.block-2-content-2-arrow');
  function fadeinJobPage(Array) {
    Array.forEach(el => {
      el.addEventListener('click', function () {
        setTimeout(() => {
          let popupCase = document.querySelector('.case_view-animate');
          let popupLoadBtns = document.querySelectorAll('.popup-onload');
          popupLoadBtns.forEach(btn => {
            btn.addEventListener('click', function () {
              setTimeout(() => {
                popupCase.classList.add('active')
                let popupCloseBtn = document.querySelector('.contact-popup__close-btn');
                popupCloseBtn.addEventListener('click', function () {
                  popupCase.classList.remove('active')
                })
              }, 350)
              // if(!popupCase.firstChild && popupCase.classList.contains('active')) {
              //     popupCase.classList.remove('active')
              // }
            })
          })
        }, 300)
      })
    })
  }
  fadeinJobPage(block2ContentHeader);
  fadeinJobPage(block2Arrow);
} else if (!jobPage) {
  let popupCase = document.querySelector('.case_view-animate');
  let popupLoadBtns = document.querySelectorAll('.popup-onload');
  popupLoadBtns.forEach(btn => {
    btn.addEventListener('click', function () {
      setTimeout(() => {
        popupCase.classList.add('active')
        let popupCloseBtn = document.querySelector('.contact-popup__close-btn');
        popupCloseBtn.addEventListener('click', function () {
          popupCase.classList.remove('active')
        })
      }, 300)
      if(!popupCase.firstChild && popupCase.classList.contains('active')) {
        popupCase.classList.remove('active')
      }
    })
  })
}
function openSub(el) {
  console.log('openSub');
  // $('.sf-sub-items').removeClass('active');
  $(el).parent().find('.sf-sub-items').toggleClass('active');
}

function setParallax(element, effectSpeed) {
  element.style.opacity = 1;

  function handleScroll() {
    const defaultSpeed = 0.1;
    const originalSpeed =
        parseFloat(element.getAttribute('data-parallax-view-speed')) ||
        defaultSpeed;
    const speed = originalSpeed / 10;
    const scroll = window.scrollY + window.innerHeight;
    const elPos = element.getBoundingClientRect().top + window.scrollY;
    let newPos = 0;

    /*let start = true;

    if(element.hasAttribute('data-parallax-view-not-mobile') === true && window.innerWidth <=640) {
      start = false;
    }*/

    if (elPos + element.clientHeight >= window.scrollY) {
      if (window.innerHeight >= elPos) {
        newPos = window.scrollY * speed;
        if (element.hasAttribute('data-parallax-view-horizontal') === true) {
          element.style.transform = `translateX(${-newPos}px)`;
        } else {
          element.style.transform = `translateY(${-newPos}px)`;
        }
      } else if (scroll >= elPos) {
        newPos = (elPos - (window.scrollY + window.innerHeight)) * speed;
        if (element.hasAttribute('data-parallax-view-horizontal') === true) {
          element.style.transform = `translateX(${newPos}px)`;
        } else {
          element.style.transform = `translateY(${newPos}px)`;
        }
      }
    }
  }

  window.addEventListener('scroll', handleScroll);
  handleScroll();
}

const parallaxElements = document.querySelectorAll('[data-parallax-view]');
parallaxElements.forEach((element) => {
  setParallax(element);
});


if (document.getElementsByClassName('we-work-section__slider')[0]) {
  const weWorkSlidersArr = document.querySelectorAll(
      '.we-work-section__slider'
  );
  weWorkSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const weWorkSwiper = new Swiper(sliderEl, {
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: document.querySelector('.we-work-section__slider-buttons .slider-btn.prev'),
        nextEl: document.querySelector('.we-work-section__slider-buttons .slider-btn.next'),
      },
      breakpoints: {
        451: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        901: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 20,
          slidesPerView: 4,
        },
      },
    });
  });
}

if (document.getElementsByClassName('management-section__slider')[0]) {
  const managementSlidersArr = document.querySelectorAll(
      '.management-section__slider'
  );
  managementSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    if(window.innerWidth <= 1200) {
      const managementSwiper = new Swiper(sliderEl, {
        slidesPerView: 1,
        spaceBetween: 20,
        threshold: 10,
        autoHeight: true,
        /*loop: loop,
        loopedSlides: loopedSlides,*/

        /*autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },*/
        navigation: {
          prevEl: document.querySelector('.management-section__slider-buttons .slider-btn.prev'),
          nextEl: document.querySelector('.management-section__slider-buttons .slider-btn.next'),
        },
        breakpoints: {
          769: {
            spaceBetween: 20,
            slidesPerView: 2,
            autoHeight: false,
          },
          1025: {
            spaceBetween: 20,
            slidesPerView: 3,
            autoHeight: false,
          },
          1200: {
            spaceBetween: 40,
            slidesPerView: 3,
            autoHeight: false,
          },
        },
      });
    }
  });
}

if (document.getElementsByClassName('contractor-section__slider')[0]) {
  const contractorSlidersArr = document.querySelectorAll(
      '.contractor-section__slider'
  );
  contractorSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;*/

    /*if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let contractorSwiper;

    function initSwiper() {
      contractorSwiper = new Swiper(sliderEl, {
        slidesPerView: 1,
        spaceBetween: 25,
        threshold: 10,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-btn.next'),
        },
        breakpoints: {
          551: {
            spaceBetween: 20,
            slidesPerView: 1.3,
          },
          651: {
            spaceBetween: 20,
            slidesPerView: 1.5,
          },
          801: {
            spaceBetween: 18,
            slidesPerView: 2,
          },
        },
      });

      /*if (!sliderEl.classList.contains('init-observer')) {
        sliderEl.classList.add('init-observer');
        swiperObserver(foundationsSwiper);
      }*/
    }

    function handleResize() {
      if (window.innerWidth <= 951) {
        initSwiper();
      } else {
        if (contractorSwiper) {
          contractorSwiper.destroy();
        }
      }
    }

    initSwiper();
    handleResize();

    window.addEventListener('resize', handleResize);
  });
}

/*if (document.getElementsByClassName('hr-section__slider')[0]) {
  const hrSlidersArr = document.querySelectorAll(
      '.hr-section__slider'
  );
  hrSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /!*let loop = false;
    let loopedSlides = false;*!/

    /!*if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*!/

    let hrSwiper;

    function initSwiper() {
      hrSwiper = new Swiper(sliderEl, {
        slidesPerView: 1,
        spaceBetween: 5,
        threshold: 10,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-btn.next'),
        },
        breakpoints: {
          551: {
            spaceBetween: 20,
            slidesPerView: 2.5,
          },
        },
      });

      /!*if (!sliderEl.classList.contains('init-observer')) {
        sliderEl.classList.add('init-observer');
        swiperObserver(foundationsSwiper);
      }*!/
    }

    function handleResize() {
      if (window.innerWidth <= 641) {
        initSwiper();
      } else {
        if (hrSwiper) {
          hrSwiper.destroy();
        }
      }
    }

    initSwiper();
    handleResize();

    window.addEventListener('resize', handleResize);
  });
}*/

if (document.getElementsByClassName('hr-section__slider')[0]) {
  const hrSlidersArr = document.querySelectorAll(
    '.hr-section__slider'
  );
  hrSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    if (window.innerWidth <= 641) {
      const hrSwiper = new Swiper(sliderEl, {
        slidesPerView: 1,
        spaceBetween: 5,
        threshold: 10,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-btn.next'),
        },
        breakpoints: {
          551: {
            spaceBetween: 20,
            slidesPerView: 2.5,
          },
        },
      });
    }
  });
}

window.choicesArray = [];

window.initChoices = function initChoices(elementsArr) {
  const choicesDOM = document.querySelector(elementsArr);
  if (choicesDOM) {
    const choicesArr = document.querySelectorAll(`${elementsArr}`);
    for (let i = 0; i < choicesArr.length; i++) {
      console.log('init');
      const parentContainer = choicesArr[i].parentNode;
      /*const list = parentContainer.querySelector(".default-select__list");*/

      const choices = new Choices(choicesArr[i], {
        searchEnabled: false,
        itemSelectText: '',
        placeholder: true,
        searchPlaceholderValue: 'Search',
      });
      choicesArray.push(choices);

      const choicesMultipleElement = parentContainer.querySelector(".choices[data-type='select-multiple']");

      if (choicesMultipleElement) {
        choicesMultipleElement.addEventListener('click', () => {
          if (parentContainer.querySelector('.is-open')) {
            choices.hideDropdown();
          }
        });
      }

      /* New multiselect logic */
      if (choicesArr[i].multiple && choicesArr[i].hasAttribute('data-multiple-list-logic')) {
        let optionName = null;
        let optionValue = null;

        const multiplePlaceholder = parentContainer.querySelector('.choices__list--multiple');

        const list = document.createElement('ul');
        list.className = 'meta-select__list';
        parentContainer.appendChild(list);

        function createListItem(optionName, optionValue) {
          const listItem = document.createElement('li');
          listItem.setAttribute('data-val', optionValue);
          listItem.innerHTML = `${optionName}`;
          listItem.classList.add('meta-select__list-item');
          list.appendChild(listItem);

          listItem.addEventListener('click', () => {
            handleListItemClick(listItem);
          });
        }
        function handleSelectedOptions() {
          list.innerHTML = '';

          const selectedOptions = Array.from(choicesArr[i].selectedOptions);

          if (selectedOptions.length >= 1) {
            list.classList.add('is-visible');
          } else {
            list.classList.remove('is-visible');
          }

          if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
            choices.setChoiceByValue('');
          }

          selectedOptions.forEach(function (option) {
            optionName = option.textContent;
            optionValue = option.value;
            if (optionName !== 'Select') {
              createListItem(optionName, optionValue);
            }
          });

          const listArr = list.querySelectorAll('.meta-select__list-item');
          if (listArr.length === 1) {
            multiplePlaceholder.textContent = optionName;
          } else if (listArr.length >= 2) {
            multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
          } else {
            multiplePlaceholder.textContent = 'Select';
          }
        }

        function handleListItemClick(listItem) {
          const optionValue = listItem.getAttribute('data-val');

          choices.removeActiveItemsByValue(optionValue);
          handleSelectedOptions();
        }

        handleSelectedOptions();

        choicesArr[i].addEventListener('change', function () {
          handleSelectedOptions();
        });

        list.addEventListener('click', function (event) {
          const liElement = event.target.closest('.meta-select__list-item');
          if (liElement) {
            handleListItemClick(liElement);
          }
        });
      }
    }
  }
};

window.initChoices('[data-choices]');

window.getChoice = function getChoice(select) {
  let selectEl = typeof select === 'object' ? select : document.querySelector(select);
  let choicesArr = window.choicesArray;
  let targetChoice;
  if (choicesArr) {
    window.choicesArray.forEach((choices) => {
      let choicesSelect = choices.passedElement.element;
      if (choicesSelect == selectEl) {
        targetChoice = choices;
      }
    });
  }
  return targetChoice;
};

window.resetChoice = function resetChoice(select) {
  let choice = getChoice(select);
  if (choice) {
    let startValue = choice.config.choices[0].value;
    choice.setChoiceByValue(startValue);
  }
};

const wheelSection = document.getElementsByClassName('wheel-section')[0];
if(wheelSection) {
  // Select various elements within the wheel
  const wheelItemsWrap = document.querySelector('.wheel__wheel-item-list');
  const itemList = document.querySelectorAll('.wheel__wheel-item');
  const itemHoverList = document.querySelectorAll('.wheel__hover g');

// Calculate sector details for positioning wheel items
  const sectorPercent = 100 / itemList.length;
  const sectorDegree = 360 / itemList.length;
  const deviationDegree = 90 - sectorDegree;
  const animationDelay = 5000;

  let uniqueColors = ['#203044', '#0EF2A2', '#6F00FF', '#FFDB29', '#0EF2A2', '#6F00FF', '#FFDB29'];

  if(wheelSection.classList.contains('contracts-page')) {
    uniqueColors = ['#203044', '#0EF2A2', '#F58EC8', '#FFDB29', '#0EF2A2', '#F58EC8', '#FFDB29'];
  }

// Calculate the positions and gradients for each wheel item
  const positionCalculate = [...itemList].reduce(
      (acc, item, idx) => {
        acc.itemRotateResult.push(
            acc.degree + sectorDegree / 2 - deviationDegree
        );
        acc.degree += sectorDegree;
        acc.percent += sectorPercent;

        // Используем остаток от деления индекса на количество цветов для выбора уникального цвета
        const color = uniqueColors[idx % uniqueColors.length];
        const gradientSector = `${color} 0, ${color} ${acc.percent}% ${
            idx !== itemList.length - 1 ? ', ' : ''
        }`;

        acc.gradientResult += gradientSector;

        return acc;
      },
      {
        percent: 0,
        degree: 0,
        gradientResult: '',
        itemRotateResult: [],
      }
  );

// Apply the calculated rotation to each wheel item
  positionCalculate.itemRotateResult.forEach((item, idx) => {
    const itemContent = itemList[idx].querySelector('.wheel__wheel-item-content');
    const itemSecondContent = itemList[idx].querySelector('.wheel__wheel-content');
    const itemCount = itemList[idx].querySelector('.wheel__wheel-count');
    itemList[idx].style.transform = `translateY(-50%) rotate(${item}deg)`;
    itemContent.style.transform = `rotate(${item * -1}deg)`;
    itemSecondContent.style.transform = `rotate(${item * -1}deg) translateY(-50%)`;
    itemCount.style.transform = `rotate(${item * -1}deg) translateY(-50%)`;
  });

// Apply the calculated gradients to the wheel background
  wheelItemsWrap.style.background = `radial-gradient(circle closest-side, transparent 100%, white 0), conic-gradient(${positionCalculate.gradientResult})`;

// Rotate the wheel items wrap to adjust the starting position
  /*wheelItemsWrap.style.transform = `rotate(${deviationDegree}deg)`;*/

  function rotateWheelToIndex(idx, scrollMultiplier) {
    const zeroIndex = Array.from(itemList).findIndex(item => item.style.transform.includes('rotate(0deg)'));

    // Вычисляем, на сколько градусов нужно повернуть колесо
    const totalItems = itemList.length;
    const itemsToRotate = (idx - zeroIndex + totalItems) % totalItems;
    /*const degreesToRotate = itemsToRotate * sectorDegree * scrollMultiplier; // Умножаем на коэффициент прокрутки*/
    const baseDegreesToRotate = itemsToRotate * sectorDegree * scrollMultiplier; // Базовый угол поворота
    const additionalRotation = 102; // Дополнительное вращение для колеса
    const totalDegreesToRotate = baseDegreesToRotate + additionalRotation; // Общий угол поворота

    // Применяем поворот к колесу
    wheelItemsWrap.style.transition = 'transform 1s ease';
    wheelItemsWrap.style.transform = `rotate(${totalDegreesToRotate}deg)`;

    itemList.forEach((item, index) => {
      const baseRotation = (positionCalculate.itemRotateResult[index] + totalDegreesToRotate) * -1;
      const additionalRotation = 0; // Дополнительное вращение
      const rotation = baseRotation + additionalRotation;
      const itemContent = item.querySelector('.wheel__wheel-item-content');
      const itemSecondContent = item.querySelector('.wheel__wheel-content');
      const itemCount = item.querySelector('.wheel__wheel-count');
      itemContent.style.transform = `rotate(${rotation}deg)`;
      itemSecondContent.style.transform = `rotate(${rotation}deg) translateY(-50%)`;
      itemCount.style.transform = `rotate(${rotation}deg) translateY(-50%)`;

      itemContent.style.transition = 'transform 1s ease';
      itemSecondContent.style.transition = 'transform 1s ease';
      itemCount.style.transition = 'transform 1s ease';

      setTimeout(() => {
        itemContent.style.transition = '';
        itemSecondContent.style.transition = '';
        itemCount.style.transition = '';
      }, 1000);
    });

    // Убираем transition после завершения анимации
    setTimeout(() => {
      wheelItemsWrap.style.transition = '';
    }, 1000);
  }
  /*rotateWheelToIndex(1, 3);*/

  /*rotateWheelToIndex(1);*/

  itemHoverList.forEach((el, i) => {
    el.addEventListener('mouseenter', () => {
      itemList[i].classList.add('is-hover');

      for (let j = 0; j < itemHoverList.length; j++) {
        if (j !== i) {
          itemHoverList[j].classList.add('is-hover');
        }
      }
    });

    el.addEventListener('mouseleave', () => {
      itemList[i].classList.remove('is-hover');

      for (let j = 0; j < itemHoverList.length; j++) {
        itemHoverList[j].classList.remove('is-hover');
      }
    });

    el.addEventListener('click', () => {
      for (let j = 0; j < itemHoverList.length; j++) {
        itemHoverList[j].classList.remove('is-hover');
        itemList[j].classList.remove('is-hover');
      }

      itemList[i].classList.add('is-hover');

      for (let j = 0; j < itemHoverList.length; j++) {
        if (j !== i) {
          itemHoverList[j].classList.add('is-hover');
        }
      }
    });
  });

  if(window.innerWidth <= 768) {
    if (document.getElementsByClassName('wheel-slider')[0]) {
      const wheelSlider = document.querySelector(
          '.wheel-slider'
      );
      const wheelSwiper = new Swiper(wheelSlider, {
        slidesPerView: 1,
        spaceBetween: 30,
        threshold: 10,
        /*loop: loop,
        loopedSlides: loopedSlides,*/

        /*autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },*/
        navigation: {
          prevEl: document.querySelector('.wheel-slider__buttons .slider-btn.prev'),
          nextEl: document.querySelector('.wheel-slider__buttons .slider-btn.next'),
        },
        on: {
          slideChange: function () {
            const activeSlideIndex = this.realIndex;
            rotateWheelToIndex(activeSlideIndex, 1);
          },
          afterInit: function () {
            const activeSlideIndex = this.realIndex;
            rotateWheelToIndex(activeSlideIndex, 1);
          }
        }
      });
    }
  }


}

if (document.querySelector('.menu-dropdown')) {
  const menuDropdownsArr = document.querySelectorAll('.menu-dropdown');
  menuDropdownsArr.forEach((menuDropdown) => {
    const dropdownList = menuDropdown.querySelector('.menu-dropdown__list');

    menuDropdown.addEventListener('click', (e) => {
      if (window.innerWidth <= 1133) {
        if (!menuDropdown.classList.contains('active')) {
          e.preventDefault();
          menuDropdown.classList.add('active');
          dropdownList.classList.add('active');
        } else {
          menuDropdown.classList.remove('active');
          dropdownList.classList.remove('active');
        }
      }
    });
  });
}





